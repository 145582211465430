import React from 'react';
import {Header, ServicesBlock, Social} from '../../components';

import HeaderBg from '../../assets/images/heade_services.jpg';

export const Services = () => {
  return (
    <>
      <Header firstWord="Services" secondWord="we provide" bgImg={HeaderBg} />
      <ServicesBlock />
      <Social />
    </>
  );
};
