import React from 'react';
import PropTypes from 'prop-types';

export const Header = ({firstWord, secondWord, bgImg}) => {
  return (
    <header className="header" style={{backgroundImage: `url(${bgImg})`}}>
      <div className="header__content">
        <h2 className="header__title">{firstWord}</h2>
        <h2 className="header__title header__title--blend">{secondWord}</h2>
      </div>
    </header>
  );
};

Header.propTypes = {
  firstWord: PropTypes.string,
  secondWord: PropTypes.string,
  bgImg: PropTypes.string,
};
