import React from 'react';
import {Link} from 'react-router-dom';
import {ROUTES} from '../../contants';

import {ReactComponent as TruckIcon} from '../../assets/images/icon/Truck.svg';
import {ReactComponent as EmployeesIcon} from '../../assets/images/icon/Employees.svg';
import {ReactComponent as MapIcon} from '../../assets/images/icon/Map.svg';
import {ReactComponent as SupportIcon} from '../../assets/images/icon/Support.svg';
import {ReactComponent as LineIcon} from '../../assets/images/icon/wave-line.svg';

export const HomeServices = () => (
  <>
    <div className="home-services">
      <div className="container">
        <div className="home-services__wr">
          <div className="home-services__left">
            <h2>Services</h2>
            <p>
              We are not just a transportation company, we will provide you with services which
              include more than just transportation. With us you have nothing to worry about. We’ll
              do our work in most efficient and effective way to minimize your job
            </p>
            <Link to={ROUTES.SERVICES} className="btn btn--white">
              <span>CHECK OUR SERVICES</span>
            </Link>
          </div>
          <div className="home-services__right">
            <div className="home-services__item">
              <div className="home-services__icon">
                <TruckIcon />
              </div>
              <div className="home-services__content">
                <div className="home-services__content_title">80+</div>
                <div className="home-services__content_text">80+ trucks across the US</div>
              </div>
            </div>
            <div className="home-services__item">
              <div className="home-services__icon">
                <EmployeesIcon />
              </div>
              <div className="home-services__content">
                <div className="home-services__content_title">150+</div>
                <div className="home-services__content_text">Employees</div>
              </div>
            </div>
            <div className="home-services__item">
              <div className="home-services__icon">
                <MapIcon />
              </div>
              <div className="home-services__content">
                <div className="home-services__content_title">48</div>
                <div className="home-services__content_text">US states</div>
              </div>
            </div>
            <div className="home-services__item">
              <div className="home-services__icon">
                <SupportIcon />
              </div>
              <div className="home-services__content">
                <div className="home-services__content_title">24/7</div>
                <div className="home-services__content_text">Support</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="home-desc">
      <div className="container">
        <div className="home-desc__wr">
          <LineIcon />
          <h2>
            With V Line Inc your freights will <br /> be shipped to any state of the USA <br />
            safely and on time
          </h2>
          <LineIcon />
        </div>
      </div>
    </div>
  </>
);
