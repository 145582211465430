import React from 'react';
import {Link} from 'react-router-dom';

import {ReactComponent as Logo} from '../../assets/images/icon/logo.svg';
import {ReactComponent as FacebookIcon} from '../../assets/images/icon/facebook.svg';
import {ReactComponent as LinkedInIcon} from '../../assets/images/icon/linkedin.svg';
import {ReactComponent as InstagramIcon} from '../../assets/images/icon/instagram.svg';

import {ROUTES} from '../../contants';

export const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div className="footer">
      <div className="container">
        <div className="footer__wr">
          <div className="footer__left">
            <Link to="/" className="footer__logo">
              <Logo />
            </Link>
            <div className="footer__copyright">
              <span>Copyright © {currentYear} Vline inc.</span>
              <span>All rights reserved</span>
            </div>
          </div>
          <div className="footer__right">
            <div className="footer__block footer__block--menu">
              <h4 className="footer__block_title">Menu</h4>
              <ul className="footer__menu">
                <li>
                  <Link to={ROUTES.HOME}>Home</Link>
                </li>
                <li>
                  <Link to={ROUTES.SERVICES}>Services</Link>
                </li>
                <li>
                  <Link to={ROUTES.HIRE}>Careers</Link>
                </li>
                <li>
                  <Link to={ROUTES.INVENTORIES}>Inventory for sale</Link>
                </li>
              </ul>
            </div>
            <div className="footer__block">
              <h4 className="footer__block_title">Stay up to date</h4>
              <a href="mailto:dispatch@vlineco.com" className="footer__email">
                dispatch@vlineco.com
              </a>
              <ul className="footer__social">
                <li>
                  <a href="https://www.instagram.com/vlineinc" target="_blank" rel="noreferrer">
                    <InstagramIcon />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/109759547512440"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FacebookIcon />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/v-line-inc"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <LinkedInIcon />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
