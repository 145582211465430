import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { Loader } from '../Loader';
import { InventoryItem } from './InventoryItem';

const url = process.env.REACT_APP_API;

export const InventoriesList = () => {
  const [inventories, setInventories] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [hasMore, setHasMore] = React.useState(false);
  const [sortOrder, setSortOrder] = React.useState('ASC');
  const [total, setTotal] = React.useState(0);

  const handleSort = React.useCallback(event => {
    setSortOrder(event.target.value);
    setPage(1);
    setInventories([]);
  }, []);

  React.useEffect(() => {
    (async () => {
      const resp = await fetch(`${url}/track?SortOrder=${sortOrder}&PerPage=5&Page=${page}`);
      const { data, total } = await resp.json();
      const newInventories = [...inventories, ...data];
      setHasMore(newInventories.length !== total);
      setInventories(newInventories);
      setTotal(total);
    })();
  }, [page, sortOrder]);

  return (
    <div className="layout container inventories">
      <div className="inventories__nav">
        <div className="inventories__total">{total} listings</div>
        <select onChange={handleSort} className="inventories__sortBy">
          <option value="ASC">Sort by: Price from Low to Height</option>
          <option value="DSC">Sort by: Price from Height to Low</option>
        </select>
      </div>
      <div className="inventories__infinite-scroll">
        <InfiniteScroll
          dataLength={inventories.length}
          next={() => setPage(p => p + 1)}
          hasMore={hasMore}
          loader={<Loader />}
          className="infinite-scroll"
        >
          {inventories.map(inventory => (
            <InventoryItem key={inventory.id} inventory={inventory} />
          ))}
        </InfiniteScroll>
      </div>
    </div>
  );
};
