export const ROUTES = Object.freeze({
  HOME: '/',
  SERVICES: '/services',
  HIRE: '/careers',
  CONTACT: '/contact-us',
  INVENTORIES: '/inventory-for-sale',
  INVENTORY: '/inventory-for-sale/:name/:id',
  LOGIN: '/login',
  NOT_FOUND: '/not-found',
});
