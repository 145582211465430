import React from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import axios from 'axios';

import {useDispatch, useSelector} from 'react-redux';
import {DataPiker, Input, MaskInput} from '../../common';
import {emailPattern} from '../../utils/emailValidator';
import {submitAdapter} from './submiAdapter';
import {setModalPosition} from '../../moduls/position/positionActions';
import {setModalConfirmModal} from '../../moduls/confirmModal';

const minUserYear = 16;

const url = `${process.env.REACT_APP_API}/EmailForm/position`;

export const PositionForm = () => {
  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      fullName: '',
      email: '',
      birthday: '',
      currentAddress: '',
      phoneNumber: '',
      lastEmployerInfo: {
        employer: '',
        position: '',
        startDate: '',
        reasonForLeaving: '',
      },
    },
  });

  const dispatch = useDispatch();
  const {id, name} = useSelector(({position: {position}}) => position);

  const date = new Date();
  const maxDate = date.setFullYear(date.getFullYear() - minUserYear);

  const onSubmit = async values => {
    const data = {
      positionId: id,
      ...values,
    };
    try {
      await axios.post(url, submitAdapter(data));
      await dispatch(setModalPosition(false));
      await dispatch(setModalConfirmModal(true));
    } catch (e) {
      console.log('error', e);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} className="positionForm">
        <div className="positionForm__info">
          Position: <span>{name}</span>
        </div>
        <div className="positionForm__wr">
          <div className="positionForm__block">
            <h4 className="positionForm__label">YOUR INFO</h4>
            <div className="positionForm__block--small">
              <Input name="fullName" label="Full name" placeholder="Type your full name here" />
              <Input
                name="email"
                type="email"
                label="Email"
                placeholder="Type your email here"
                validation={{
                  pattern: {
                    value: emailPattern,
                    message: 'Email is not valid',
                  },
                }}
              />
            </div>
            <Input
              name="currentAddress"
              label="Current address"
              placeholder="Type your address here"
            />
            <DataPiker
              name="birthday"
              placeholder="MM/DD/YYYY"
              label="Date of birth"
              maxDate={maxDate}
            />
            <MaskInput name="phoneNumber" label="Cell phone number" />
          </div>
          <div className="positionForm__block">
            <h4 className="positionForm__label">ABOUT YOUR LAST EMPLOYER</h4>
            <Input
              name="employer"
              label="Last employer"
              placeholder="Who was your last employer?"
            />
            <Input name="position" label="Position" placeholder="What was your position?" />
            <Input name="startDate" label="Years worked" placeholder="How many years/months?" />
            <Input
              name="reasonForLeaving"
              label="Reason for leaving"
              placeholder="Why did you quit?"
            />
          </div>
        </div>
        <button type="submit" className="btn btn--black">
          <span>Apply</span>
        </button>
      </form>
    </FormProvider>
  );
};
