import axios from 'axios';

export const getImageAPI = identifier => {
  const url = `${process.env.REACT_APP_API}/file/${identifier}`;

  return axios
    .get(url, {
      responseType: 'blob',
    })
    .then(resp => URL.createObjectURL(resp.data));
};
