import {actionCreator} from '../utils/actionCreator';

const initialState = {
  isOpenModal: false,
};

export const confirmModalReducer = (state = initialState, action) => {
  const {type, payload} = action;

  switch (type) {
    case SET_MODAL_CONFIRM_MODAL: {
      return {
        ...state,
        isOpenModal: payload,
      };
    }

    default:
      return state;
  }
};

export const SET_MODAL_CONFIRM_MODAL = 'SET_MODAL_CONFIRM_MODAL';

export const setModalConfirmModal = payload => actionCreator(SET_MODAL_CONFIRM_MODAL, payload);
