import Img0 from '../../assets/images/track/Img0000.png';
import Img1 from '../../assets/images/track/Img0001.png';
import Img2 from '../../assets/images/track/Img0002.png';
import Img3 from '../../assets/images/track/Img0003.png';
import Img4 from '../../assets/images/track/Img0004.png';
import Img5 from '../../assets/images/track/Img0005.png';
import Img6 from '../../assets/images/track/Img0006.png';
import Img7 from '../../assets/images/track/Img0007.png';
import Img8 from '../../assets/images/track/Img0008.png';
import Img9 from '../../assets/images/track/Img0009.png';
import Img10 from '../../assets/images/track/Img0010.png';
import Img11 from '../../assets/images/track/Img0011.png';
import Img12 from '../../assets/images/track/Img0012.png';
import Img13 from '../../assets/images/track/Img0013.png';
import Img14 from '../../assets/images/track/Img0014.png';
import Img15 from '../../assets/images/track/Img0015.png';
import Img16 from '../../assets/images/track/Img0016.png';
import Img17 from '../../assets/images/track/Img0017.png';
import Img18 from '../../assets/images/track/Img0018.png';
import Img19 from '../../assets/images/track/Img0019.png';
import Img20 from '../../assets/images/track/Img0020.png';
import Img21 from '../../assets/images/track/Img0021.png';
import Img22 from '../../assets/images/track/Img0022.png';
import Img23 from '../../assets/images/track/Img0023.png';
import Img24 from '../../assets/images/track/Img0024.png';
import Img25 from '../../assets/images/track/Img0025.png';
import Img26 from '../../assets/images/track/Img0026.png';
import Img27 from '../../assets/images/track/Img0027.png';
import Img28 from '../../assets/images/track/Img0028.png';
import Img29 from '../../assets/images/track/Img0029.png';
import Img30 from '../../assets/images/track/Img0030.png';
import Img31 from '../../assets/images/track/Img0031.png';

export const images = [
  Img0,
  Img1,
  Img2,
  Img3,
  Img4,
  Img5,
  Img6,
  Img7,
  Img8,
  Img9,
  Img10,
  Img11,
  Img12,
  Img13,
  Img14,
  Img15,
  Img16,
  Img17,
  Img18,
  Img19,
  Img20,
  Img21,
  Img22,
  Img23,
  Img24,
  Img25,
  Img26,
  Img27,
  Img28,
  Img29,
  Img30,
  Img31,
];
