import axios from 'axios';

import {errorAction, fetchAction, startAction} from '../../store/actions';
import {ERROR_FETCH_INVENTORY, START_FETCH_INVENTORY, SUCCESS_FETCH_INVENTORY} from './types';
import {fetchAttachmentsImages, fetchMainPhotoImages} from '../Media/actions';

export const fetchInventory = id => async dispatch => {
  dispatch(startAction(START_FETCH_INVENTORY));
  try {
    const {data} = await axios(`${process.env.REACT_APP_API}/track/${id}`);
    dispatch(fetchAction({type: SUCCESS_FETCH_INVENTORY, payload: data}));
    dispatch(fetchMainPhotoImages({mainPhotoIdentifier: data.mainPhotoIdentifier}));
    dispatch(fetchAttachmentsImages({attachments: data.attachments}));
  } catch (error) {
    dispatch(errorAction({type: ERROR_FETCH_INVENTORY, error}));
  }
};
