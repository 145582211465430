import React from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import axios from 'axios';

import {useDispatch} from 'react-redux';
import {Input, MaskInput} from '../../common';
import {TextArea} from '../../common/Input';
import {emailPattern} from '../../utils/emailValidator';
import {setModalConfirmModal} from '../../moduls/confirmModal';
import {ConfirmModal} from '../Modal/ConfirmModal/ConfirmModal';

const url = `${process.env.REACT_APP_API}/EmailForm/contact-us`;

export const ContactForm = () => {
  const methods = useForm({
    mode: 'onChange',
  });
  const dispatch = useDispatch();

  const onSubmit = async values => {
    try {
      await axios.post(url, values);
      await dispatch(setModalConfirmModal(true));
    } catch (e) {
      console.log('error', e);
    }
  };

  return (
    <div className="contact__form">
      <h4 className="contact__title">Want to know more?</h4>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Input
            name="fullName"
            label="Your name"
            placeholder="Type your name"
            className="field--fullName"
          />
          <Input
            name="email"
            type="email"
            label="Email"
            placeholder="Type your email"
            validation={{
              pattern: {
                value: emailPattern,
                message: 'Email is not valid',
              },
            }}
            className="field--email"
          />
          <MaskInput name="phoneNumber" label="Phone number" className="field--phone" />
          <TextArea
            name="message"
            label="Message"
            placeholder="Type your message"
            className="field--message"
          />
          <button type="submit" className="btn btn--black">
            <span>SEND</span>
          </button>
        </form>
      </FormProvider>
      <ConfirmModal />
    </div>
  );
};
