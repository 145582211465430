import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';

import { Position } from './Position';
import { Loader } from '../Loader';
import { Modal } from '../Modal';
import { PositionForm } from './PositionForm';
import { setDataPosition, setModalPosition } from '../../moduls/position/positionActions';
import { ConfirmModal } from '../Modal/ConfirmModal/ConfirmModal';

const url = process.env.REACT_APP_API;

export const Positions = () => {
  const [positions, setPositions] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [hasMore, setHasMore] = React.useState(false);

  const dispatch = useDispatch();
  const isOpenModal = useSelector(state => state.position.isOpenModal);

  const handleClick = data => {
    dispatch(setDataPosition(data));
    dispatch(setModalPosition(true));
    window.scrollTo({ top: 70, left: 0, behavior: 'smooth' });
  };
  const handleClose = () => dispatch(setModalPosition(false));

  React.useEffect(() => {
    (async () => {
      const resp = await fetch(`${url}/position?SortOrder=CreatedAt&PerPage=5&Page=${page}`);
      const { data, total } = await resp.json();
      const newPositions = [...positions, ...data].filter(Boolean).flat();
      setHasMore(newPositions.length !== total);
      setPositions(newPositions);
    })();
  }, [page]);

  return (
    <div className="layout container positions__layout">
      <Modal isOpen={isOpenModal} onClose={handleClose} label="Apply form">
        <PositionForm />
      </Modal>
      <ConfirmModal />
      <div className="positions__title">open positions</div>
      <div className="positions">
        <InfiniteScroll
          dataLength={positions.length}
          next={() => setPage(p => p + 1)}
          hasMore={hasMore}
          loader={<Loader />}
          className="infinite-scroll"
        >
          {positions.map(position => (
            <Position
              key={position.id}
              position={position}
              handleClick={() => handleClick(position)}
            />
          ))}
        </InfiniteScroll>
      </div>
    </div>
  );
};
