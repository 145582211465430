import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {Modal} from '../Modal';
import {setModalConfirmModal} from '../../../moduls/confirmModal';

import {ReactComponent as ConfirmIcon} from '../../../assets/images/icon/ConfirmIcon.svg';

export const ConfirmModal = () => {
  const dispatch = useDispatch();
  const isOpenModal = useSelector(({confirmModal: {isOpenModal}}) => isOpenModal);

  const handleClose = () => dispatch(setModalConfirmModal(false));

  return (
    <Modal isOpen={isOpenModal} onClose={handleClose} className="confirmModal">
      <div className="confirmModal__wr">
        <ConfirmIcon />
        <h4>Thank You!</h4>
        <p>
          Our manager will contact you as <br /> soon as posible
        </p>
        <button className="btn btn--black" onClick={handleClose}>
          <span>Great!</span>
        </button>
      </div>
    </Modal>
  );
};
