import React from 'react';
import PropTypes from 'prop-types';

import {ReactComponent as LeftIcon} from 'assets/images/icon/left.svg';
import {ReactComponent as RightIcon} from 'assets/images/icon/right.svg';

export const PrevArrow = ({onClick, className}) => (
  <button type="button" className={`arrow carousel__arrow--left ${className}`} onClick={onClick}>
    <LeftIcon />
  </button>
);

export const NextArrow = ({onClick, className}) => (
  <button type="button" className={`arrow carousel__arrow--right ${className}`} onClick={onClick}>
    <RightIcon />
  </button>
);

PrevArrow.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
};

NextArrow.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
};
