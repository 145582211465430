import {SET_DATA_POSITION, SET_MODAL_POSITION} from './positionTypes';

const initialState = {
  position: {
    id: null,
    name: null,
    description: null,
    positionName: null,
    location: null,
    shift: null,
    salary: null,
    experience: null,
    requirements: null,
  },
  isOpenModal: false,
};

export const positionReducer = (state = initialState, action) => {
  const {type, payload} = action;

  switch (type) {
    case SET_DATA_POSITION: {
      return {
        ...state,
        position: {...payload},
      };
    }

    case SET_MODAL_POSITION: {
      return {
        ...state,
        isOpenModal: payload,
      };
    }

    default:
      return state;
  }
};
