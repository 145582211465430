import React from 'react';
import {useSelector} from 'react-redux';
import {divisionOfNumber} from '../../utils/divisionOfNumber';

export const InventoryAdditionalInfo = () => {
  const {
    engineMake,
    engineModel,
    engineHP,
    transMake,
    transModel,
    suspensionType,
    sleeperType,
    wheelBase,
    fifthWheel,
    engineBrake,
    frameMaterial,
    color,
    interior,
    serialNumber,
    odometer,
    hourse,
  } = useSelector(({inventory}) => inventory);

  return (
    <div className="inventory__info">
      <h4>Additional info</h4>
      <ul className="inventory__info_list">
        {engineMake && (
          <li>
            <strong>Engine Make</strong>
            {engineMake}
          </li>
        )}
        {engineModel && (
          <li>
            <strong>Engine Model</strong>
            {engineModel}
          </li>
        )}
        {engineHP !== 0 && (
          <li>
            <strong>Engine HP</strong>
            {engineHP}
          </li>
        )}
        {transMake && (
          <li>
            <strong>Trans Make</strong>
            {transMake}
          </li>
        )}
        {transModel && (
          <li>
            <strong>Trans Model</strong>
            {transModel}
          </li>
        )}
        {suspensionType && (
          <li>
            <strong>Suspension Type</strong>
            {suspensionType}
          </li>
        )}
        {sleeperType && (
          <li>
            <strong>Sleeper Type</strong>
            {sleeperType}
          </li>
        )}
        {wheelBase && (
          <li>
            <strong>Wheelbase</strong>
            {wheelBase}
          </li>
        )}
        {fifthWheel && (
          <li>
            <strong>5th Wheel</strong>
            {fifthWheel}
          </li>
        )}
        {engineBrake && (
          <li>
            <strong>Engine Brake</strong>
            {engineBrake}
          </li>
        )}
        {frameMaterial && (
          <li>
            <strong>Frame Material</strong>
            {frameMaterial}
          </li>
        )}
        {color && (
          <li>
            <strong>Color</strong>
            {color}
          </li>
        )}
        {interior && (
          <li>
            <strong>Interior</strong>
            {interior}
          </li>
        )}
        {serialNumber && (
          <li>
            <strong>Serial No.</strong>
            {serialNumber}
          </li>
        )}
        {odometer !== 0 && (
          <li>
            <strong>Odometer</strong>
            {divisionOfNumber(odometer)}
          </li>
        )}
        {hourse && (
          <li>
            <strong>Hours</strong>
            {hourse}
          </li>
        )}
      </ul>
    </div>
  );
};
