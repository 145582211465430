import {ERROR_FETCH_INVENTORY, START_FETCH_INVENTORY, SUCCESS_FETCH_INVENTORY} from './types';

const initialState = {
  id: null,
  name: null,
  mainPhotoIdentifier: null,
  description: null,
  price: null,
  phoneNumber: null,
  email: null,
  make: null,
  model: null,
  year: null,
  horsepower: null,
  transSpeed: null,
  trackType: null,
  engineMake: null,
  engineModel: null,
  engineHP: null,
  transMake: null,
  transModel: null,
  suspensionType: null,
  sleeperType: null,
  wheelBase: null,
  fifthWheel: null,
  engineBrake: null,
  frameMaterial: null,
  color: null,
  interior: null,
  serialNumber: null,
  odometer: null,
  hourse: null,
  attachments: [],
  error: null,
  loading: false,
};

export const inventoryReducer = (state = initialState, action) => {
  const {type, payload} = action;

  switch (type) {
    case SUCCESS_FETCH_INVENTORY: {
      return {
        ...state,
        ...payload,
        loading: false,
      };
    }

    case START_FETCH_INVENTORY: {
      return {
        ...state,
        loading: true,
      };
    }

    case ERROR_FETCH_INVENTORY: {
      return {
        ...state,
        error: false,
      };
    }

    default:
      return state;
  }
};
