import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

export const Portal = ({children, onClose}) => {
  const [container] = React.useState(() => document.createElement('div'));

  React.useEffect(() => {
    const close = e => {
      if (e.keyCode === 27) onClose();
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  }, [onClose]);

  React.useEffect(() => {
    document.body.appendChild(container);
    return () => document.body.removeChild(container);
  }, [container]);

  return ReactDOM.createPortal(children, container);
};

Portal.propTypes = {
  children: PropTypes.element,
  onClose: PropTypes.func,
};
