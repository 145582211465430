import React from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Loader, Social } from 'components';
import { Carousel } from 'components/Carousel/Carousel';
import { InventoryAdditionalInfo, InventoryDescription } from 'components/Inventory';

import { ROUTES } from 'contants';
import { fetchInventory } from 'moduls/Invenroty/actions';

import { ReactComponent as LeftIcon } from 'assets/images/icon/left.svg';

export const Inventory = () => {
  const history = useHistory();
  const { id } = useParams();
  const { error, loading } = useSelector(({ inventory }) => inventory);
  const { attachments, mainPhoto } = useSelector(({ media }) => media);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchInventory(id));
  }, [id, dispatch]);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <Redirect to={ROUTES.NOT_FOUND} />;
  }

  return (
    <>
      <div className="container inventory">
        <button className="goBack" onClick={() => history.push(ROUTES.INVENTORIES)}>
          <LeftIcon /> Back to Inventory for sale
        </button>
        <div className="inventory__top">
          <Carousel images={[mainPhoto, ...attachments]} />
          <InventoryDescription />
        </div>
        <InventoryAdditionalInfo />
      </div>
      <Social />
    </>
  );
};
