import React from "react";
import { ReactComponent as MailIcon } from "assets/images/icon/email.svg";
import { ReactComponent as PhoneIcon } from "assets/images/icon/phone.svg";
import { ReactComponent as MapIcon } from "assets/images/icon/Map.svg";
import { ReactComponent as ClockIcon } from "assets/images/icon/clock.svg";

export const ContactDescription = (props) => {
  return (
    <div className="contact__description">
      <h4 className="contact__title">Our contacts</h4>
      <div className="contact__description_item">
        <div className="icon">
          <MailIcon />
        </div>
        <a href="mailto:dispatch@vlineco.com">dispatch@vlineco.com</a>
      </div>
      <div className="contact__description_item">
        <div className="icon">
          <PhoneIcon />
        </div>
        <a href="tel:+13153088046">+1 315-308-8046</a>
      </div>
      <div className="contact__description_item">
        <div className="icon">
          <MapIcon />
        </div>
        <a
          href="https://www.google.com/maps?q=151+Falso+Dr,+Syracuse,+NY+13211&um=1&ie=UTF-8&sa=X&ved=2ahUKEwiXoMvMoMzwAhWkCRAIHWptDbAQ_AUoAXoECAEQAw"
          target="_blank"
          rel="noreferrer"
        >
          151 Falso Dr, Syracuse, NY 13211
        </a>
      </div>
      <div className="contact__description_item">
        <div className="icon">
          <MapIcon />
        </div>
        <a
          href="https://www.google.com/maps?q=1176+East+California+St,+Ontario,+CA+91761&um=1&ie=UTF-8&sa=X&ved=2ahUKEwiEreCL-_j8AhUQlosKHTMaD1cQ_AUoAXoECAEQAw"
          target="_blank"
          rel="noreferrer"
        >
          1176 East California St, Ontario, CA 91761
        </a>
      </div>
      <div className="contact__description_item">
        <div className="icon">
          <MapIcon />
        </div>
        <a
          href="https://www.google.com/maps/place/5+S+Harper+Ave,+Chicago,+IL+60628,+USA/@41.7206035,-87.5892215,17z/data=!3m1!4b1!4m5!3m4!1s0x880e26312a002533:0x35708f89bd8d8ab1!8m2!3d41.7206035!4d-87.5870328"
          target="_blank"
          rel="noreferrer"
        >
          5 S Harper Ave, Chicago, IL 60628
        </a>
      </div>
      <div className="contact__description_item">
        <div className="icon">
          <MapIcon />
        </div>
        <a
          href="https://www.google.com/maps?q=375+W+Scotts+Ave,+Stockton,+CA+95203&um=1&ie=UTF-8&sa=X&ved=2ahUKEwj3waGY_Pj8AhXS6CoKHdU_AEMQ_AUoAXoECAEQAw"
          target="_blank"
          rel="noreferrer"
        >
          375 W Scotts Ave, Stockton, CA 95203
        </a>
      </div>
      <div className="contact__description_item">
        <div className="icon">
          <ClockIcon />
        </div>
        <p>Mon-Fri 8:00 am - 6:00 pm</p>
      </div>
    </div>
  );
};
