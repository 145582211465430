import React from 'react';
import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import ServiceBlock1 from '../../assets/images/serviceBlock/serviceBlock1.jpeg';
import ServiceBlock2 from '../../assets/images/serviceBlock/serviceBlock2.jpeg';
import ServiceBlock3 from '../../assets/images/serviceBlock/serviceBlock3.jpeg';
import ServiceBlock4 from '../../assets/images/serviceBlock/serviceBlock4.jpeg';
import ServiceBlock5 from '../../assets/images/serviceBlock/serviceBlock5.jpeg';

export const ServicesBlock = () => {
  const textBlock1 = React.useRef(null);
  const imageBlock1 = React.useRef(null);
  const textBlock2 = React.useRef(null);
  const imageBlock2 = React.useRef(null);
  const textBlock3 = React.useRef(null);
  const imageBlock3 = React.useRef(null);
  const textBlock4 = React.useRef(null);
  const imageBlock4 = React.useRef(null);
  const textBlock5 = React.useRef(null);
  const imageBlock5 = React.useRef(null);

  React.useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    gsap.from(textBlock2.current, {
      x: '100%',
      opacity: 0.3,
    });
    gsap.from(imageBlock2.current, {
      x: '-100%',
      opacity: 0.3,
    });

    gsap.from(textBlock3.current, {
      x: '-100%',
      opacity: 0.3,
    });
    gsap.from(imageBlock3.current, {
      x: '100%',
      opacity: 0.3,
    });

    gsap.from(textBlock5.current, {
      x: '-100%',
      opacity: 0.3,
    });
    gsap.from(imageBlock5.current, {
      x: '100%',
      opacity: 0.3,
    });

    gsap.from(textBlock4.current, {
      x: '100%',
      opacity: 0.3,
    });
    gsap.from(imageBlock4.current, {
      x: '-100%',
      opacity: 0.3,
    });

    gsap.to(textBlock2.current, {
      scrollTrigger: {
        trigger: textBlock1.current,
        toggleActions: 'restart none none none',
        scrub: 1,
      },
      x: 0,
      opacity: 1,
      duration: 5,
    });

    gsap.to(imageBlock2.current, {
      scrollTrigger: {
        trigger: textBlock1.current,
        toggleActions: 'restart none none none',
        scrub: 1,
      },
      x: 0,
      opacity: 1,
      duration: 5,
    });

    gsap.to(textBlock4.current, {
      scrollTrigger: {
        trigger: textBlock3.current,
        toggleActions: 'restart none none none',
        scrub: 1,
      },
      x: 0,
      opacity: 1,
      duration: 5,
    });

    gsap.to(imageBlock3.current, {
      scrollTrigger: {
        trigger: textBlock2.current,
        toggleActions: 'restart none none none',
        scrub: 1,
      },
      x: 0,
      opacity: 1,
      duration: 5,
    });

    gsap.to(textBlock3.current, {
      scrollTrigger: {
        trigger: textBlock2.current,
        toggleActions: 'restart none none none',
        scrub: 1,
      },
      x: 0,
      opacity: 1,
      duration: 5,
    });

    gsap.to(imageBlock4.current, {
      scrollTrigger: {
        trigger: textBlock3.current,
        toggleActions: 'restart none none none',
        scrub: 1,
      },
      x: 0,
      opacity: 1,
      duration: 5,
    });

    gsap.to(textBlock5.current, {
      scrollTrigger: {
        trigger: textBlock4.current,
        toggleActions: 'restart none none none',
        scrub: 1,
      },
      x: 0,
      opacity: 1,
      duration: 5,
    });

    gsap.to(imageBlock5.current, {
      scrollTrigger: {
        trigger: textBlock4.current,
        toggleActions: 'restart none none none',
        scrub: 1,
      },
      x: 0,
      opacity: 1,
      duration: 5,
    });
  }, []);

  return (
    <div className="layout container services__layout">
      <div className="services__block">
        <div className="services__content" ref={textBlock1}>
          <h4>Trucking services</h4>
          <p>
            Even as a top of the line intermodal freight service, our roots in trucking remain at
            the core of our business. Our experienced drivers are dedicated to transporting goods
            efficiently and safely. We train all of our employees to make sure that transfers are
            done seamlessly so that time is not lost in transition.
          </p>
        </div>
        <div className="services__img">
          <img src={ServiceBlock1} alt="services image" ref={imageBlock1} />
        </div>
      </div>

      <span className="services__decor" />

      <div className="services__block">
        <div className="services__content" ref={textBlock2}>
          <h4>Cross-docking</h4>
          <p>
            Our warehousing and distribution services are organized by a state of the art inventory
            system, ensuring that cargo ends up divided into the right trucks and sent to the right
            destination at the right time. Our warehouses are protected with 24 hour security
            systems
          </p>
        </div>
        <div className="services__img" ref={imageBlock2}>
          <img src={ServiceBlock2} alt="services image" />
        </div>
      </div>

      <span className="services__decor" />

      <div className="services__block">
        <div className="services__content" ref={textBlock3}>
          <h4>Complex logistics</h4>
          <p>
            Complicated logistics are our specialty. All you have to do is let us know what your
            shipping goals are, and we will work out the most efficient way to get your cargo where
            it needs to be. Questions? We are happy to give you a detailed report on where
            everything will be along the way.
          </p>
        </div>
        <div className="services__img">
          <img src={ServiceBlock3} alt="services image" ref={imageBlock3} />
        </div>
      </div>

      <span className="services__decor" />

      <div className="services__block">
        <div className="services__content" ref={textBlock4}>
          <h4>Dry van services</h4>
          <p>
            Regardless of the weight, size or quantity of your product, with our 53’ length dry vans
            we can load any of your freight and deliver it to any place in the USA safely and
            quickly
          </p>
        </div>
        <div className="services__img">
          <img src={ServiceBlock4} alt="services image" ref={imageBlock4} />
        </div>
      </div>

      <span className="services__decor" />

      <div className="services__block">
        <div className="services__content" ref={textBlock5}>
          <h4>Reefer services</h4>
          <p>
            We move temperature-sensitive products as well as dry loads. We understand how important
            it is to keep the product in the specific conditions, that’s why our reefers are
            monitored and inspected before every trip.
          </p>
        </div>
        <div className="services__img">
          <img src={ServiceBlock5} alt="services image" ref={imageBlock5} />
        </div>
      </div>
    </div>
  );
};
