import React from 'react';
import {useFormContext} from 'react-hook-form';
import PropTypes from 'prop-types';
import {ErrorMessage} from '@hookform/error-message';

export const Input = ({
  name,
  type,
  label,
  errorMessage,
  validation,
  placeholder,
  disabled,
  className = '',
}) => {
  const {
    register,
    formState: {errors},
  } = useFormContext();

  const error = errors?.[name];

  return (
    <div className={`field ${className}`}>
      <label htmlFor="field" className="field__label">
        {label && <span className="field__text">{label}</span>}
        <span className="field__text field__error">
          <ErrorMessage errors={errors} name={name} message={errorMessage} />
        </span>
      </label>
      <input
        id="field"
        name={name}
        type={type}
        placeholder={placeholder}
        disabled={disabled}
        className={`field__input ${error && 'error'}`}
        {...register(name, {required: 'Required', ...validation})}
      />
    </div>
  );
};

Input.defaultProps = {
  type: 'text',
};

Input.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
  disabled: PropTypes.bool,
  validation: PropTypes.object,
  className: PropTypes.string,
};
