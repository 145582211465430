import React from 'react';
import {Link, NavLink} from 'react-router-dom';

import {ROUTES} from '../../contants';

import {ReactComponent as Logo} from '../../assets/images/icon/logo.svg';

export const Nav = () => {
  const [openMenu, setOpenMenu] = React.useState(false);
  const handleToggleMenu = () => setOpenMenu(p => !p);
  const handleCloseMenu = () => setOpenMenu(false);
  const stateMenuClass = openMenu ? 'on' : '';
  return (
    <nav className="nav">
      <div className="container">
        <div className="nav__wr">
          <Link to={ROUTES.HOME}>
            <Logo />
          </Link>
          <div className={`nav__menu ${stateMenuClass}`}>
            <ul className="nav__list">
              <li className="nav__item">
                <NavLink
                  exact
                  to={ROUTES.HOME}
                  className="nav__link"
                  activeClassName="isActive"
                  onClick={handleCloseMenu}
                >
                  <span>Home</span>
                </NavLink>
              </li>
              <li className="nav__item">
                <NavLink
                  to={ROUTES.SERVICES}
                  className="nav__link"
                  activeClassName="isActive"
                  onClick={handleCloseMenu}
                >
                  <span>Services</span>
                </NavLink>
              </li>
              <li className="nav__item">
                <NavLink
                  to={ROUTES.HIRE}
                  className="nav__link"
                  activeClassName="isActive"
                  onClick={handleCloseMenu}
                >
                  <span>Careers</span>
                </NavLink>
              </li>
              <li className="nav__item">
                <NavLink
                  to={ROUTES.CONTACT}
                  className="nav__link"
                  activeClassName="isActive"
                  onClick={handleCloseMenu}
                >
                  <span>Contact us</span>
                </NavLink>
              </li>
              <li className="nav__item">
                <NavLink
                  to={ROUTES.INVENTORIES}
                  className="nav__link"
                  activeClassName="isActive"
                  onClick={handleCloseMenu}
                >
                  <span>Inventory for sale</span>
                </NavLink>
              </li>
            </ul>
            <a
              href="https://vlineco.pinghq.com"
              target="_blank"
              className="nav__login btn btn--black"
              rel="noreferrer"
            >
              <span>
                <svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M7.032 3.168L9.432 5.568C9.49007 5.62397 9.53626 5.69107 9.56781 5.7653C9.59936 5.83952 9.61562 5.91935 9.61562 6C9.61562 6.08065 9.59936 6.16048 9.56781 6.2347C9.53626 6.30893 9.49007 6.37603 9.432 6.432L7.032 8.832C6.94808 8.91915 6.83978 8.9789 6.7213 9.0034C6.60283 9.0279 6.47971 9.01601 6.36811 8.9693C6.25651 8.92258 6.16164 8.84322 6.09594 8.74162C6.03025 8.64003 5.99681 8.52094 6 8.4V6.6H0.6C0.44087 6.6 0.288258 6.53679 0.175736 6.42426C0.0632141 6.31174 0 6.15913 0 6C0 5.84087 0.0632141 5.68826 0.175736 5.57574C0.288258 5.46321 0.44087 5.4 0.6 5.4H6V3.6C5.99681 3.47906 6.03025 3.35998 6.09594 3.25838C6.16164 3.15678 6.25651 3.07742 6.36811 3.0307C6.47971 2.98399 6.60283 2.9721 6.7213 2.9966C6.83978 3.0211 6.94808 3.08085 7.032 3.168ZM8.4 0C8.24087 0 8.08826 0.0632141 7.97574 0.175736C7.86321 0.288258 7.8 0.44087 7.8 0.6C7.8 0.75913 7.86321 0.911742 7.97574 1.02426C8.08826 1.13679 8.24087 1.2 8.4 1.2H9.6C9.91826 1.2 10.2235 1.32643 10.4485 1.55147C10.6736 1.77652 10.8 2.08174 10.8 2.4V9.6C10.8 9.91826 10.6736 10.2235 10.4485 10.4485C10.2235 10.6736 9.91826 10.8 9.6 10.8H8.4C8.24087 10.8 8.08826 10.8632 7.97574 10.9757C7.86321 11.0883 7.8 11.2409 7.8 11.4C7.8 11.5591 7.86321 11.7117 7.97574 11.8243C8.08826 11.9368 8.24087 12 8.4 12H9.6C10.2365 12 10.847 11.7471 11.2971 11.2971C11.7471 10.847 12 10.2365 12 9.6V2.4C12 1.76348 11.7471 1.15303 11.2971 0.702944C10.847 0.252856 10.2365 0 9.6 0H8.4Z"
                    fill="white"
                  />
                </svg>
                Login
              </span>
            </a>
          </div>
          <div className={`nav__toggle ${stateMenuClass}`} onClick={handleToggleMenu}>
            <span />
          </div>
        </div>
      </div>
    </nav>
  );
};
