import React from 'react';

import FacebookIcon from '../../assets/images/icon/facebook.svg';
import LinkedInIcon from '../../assets/images/icon/linkedin.svg';
import InstagramIcon from '../../assets/images/icon/instagram.svg';

import Img1 from '../../assets/images/soc-img-1.jpg';
import Img2 from '../../assets/images/soc-img-2.jpg';
import Img3 from '../../assets/images/soc-img-3.jpg';

export const Social = () => {
  return (
    <div className="social">
      <div className="container">
        <div className="social__wr">
          <div className="social__content">
            <h4>
              Follow us in <br /> social media
            </h4>
            <p>
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              Let's keep in touch
            </p>
            <div className="social__btns">
              <a
                href="https://www.facebook.com/109759547512440"
                className="social__btn"
                target="_blank"
                rel="noreferrer"
              >
                <img src={FacebookIcon} alt="FacebookIcon" />
                <span>Facebook</span>
              </a>
              <a
                href="https://www.linkedin.com/company/v-line-inc"
                className="social__btn"
                target="_blank"
                rel="noreferrer"
              >
                <img src={LinkedInIcon} alt="LinkedInIcon" />
                <span>LinkedIn</span>
              </a>
              <a
                href="https://www.instagram.com/vlineinc"
                className="social__btn"
                target="_blank"
                rel="noreferrer"
              >
                <img src={InstagramIcon} alt="InstagramIcon" />
                <span>Instagram</span>
              </a>
            </div>
          </div>
          <div className="social__images">
            <div className="social__images_img social__images_img-1">
              <img src={Img1} alt="img" />
            </div>
            <div className="social__images_img social__images_img-2">
              <img src={Img2} alt="img" />
            </div>
            <div className="social__images_img social__images_img-3">
              <img src={Img3} alt="img" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
