import React from 'react';
import {BrowserRouter, Redirect, Route, Switch, useLocation} from 'react-router-dom';

import {Contacts, Hire, Home, InventoriesForSale, Inventory, NotFound, Services} from './pages';
import {Footer, Nav} from './components';
import {ROUTES} from './contants';

const ScrollToTop = () => {
  const {pathname} = useLocation();

  React.useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, [pathname]);

  return null;
};

export const App = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Nav />

      <Switch>
        <Route exact path={ROUTES.HOME} component={Home} />
        <Route exact path={ROUTES.SERVICES} component={Services} />
        <Route exact path={ROUTES.HIRE} component={Hire} />
        <Route exact path={ROUTES.CONTACT} component={Contacts} />
        <Route exact path={ROUTES.INVENTORIES} component={InventoriesForSale} />
        <Route exact path={ROUTES.INVENTORY} component={Inventory} />
        <Route exact path={ROUTES.NOT_FOUND} component={NotFound} />
        <Route path="*" component={NotFound} />
        <Redirect to={ROUTES.NOT_FOUND} />
      </Switch>

      <Footer />
    </BrowserRouter>
  );
};
