import React from 'react';
import PropTypes from 'prop-types';

export const Position = ({position, handleClick}) => {
  const {
    name,
    description,
    positionName,
    location,
    shift,
    salary,
    experience,
    requirements,
  } = position;
  return (
    <>
      <div className="position" onClick={handleClick}>
        <div className="position__title">{name}</div>
        <div className="position__desc">{description}</div>
        <div className="position__options">
          <div className="position__option">
            <div className="position__item">
              <strong>Position:</strong>
              <span>{positionName}</span>
            </div>
            <div className="position__item">
              <strong>Location:</strong>
              <span>{location}</span>
            </div>
          </div>
          <div className="position__option">
            <div className="position__item">
              <strong>Shift:</strong>
              <span>{shift}</span>
            </div>
            <div className="position__item">
              <strong>Salary:</strong>
              <span>{salary}</span>
            </div>
          </div>
          <div className="position__option">
            <div className="position__item">
              <strong>Experience:</strong>
              <span>{experience}</span>
            </div>
            {requirements && (
              <div className="position__item">
                <strong>Requirements:</strong>
                <span>{requirements}</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

Position.propTypes = {
  position: PropTypes.object.isRequired,
  handleClick: PropTypes.func,
};
