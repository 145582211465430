import React from 'react';
import {Link} from 'react-router-dom';

import {ROUTES} from '../../contants';

import JoinTeamImg from '../../assets/images/join-team.png';

export const JoinTeam = () => (
  <div className="join-team">
    <div className="container">
      <div className="join-team__wr">
        <div className="join-team__image">
          <img src={JoinTeamImg} alt="join-team image" />
        </div>
        <div className="join-team__content">
          <h4>Join Our Team</h4>
          <p>
            We are happy to see you as a part of our big family. We have a lot of positions open for
            you to join our fleet.
          </p>
          <Link to={ROUTES.HIRE} className="btn btn--black">
            <span>SEE JOBS</span>
          </Link>
        </div>
      </div>
    </div>
  </div>
);
