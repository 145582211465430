import React from "react";

import { ContactDescription, ContactForm, Social } from "components";

import stylesMap from "./map-styles.json";

export const Contacts = () => {
  React.useEffect(
    () =>
      (() => {
        const positionFirst = { lat: 43.09315, lng: -76.109615 };
        const positionSecond = { lat: 34.0557585, lng: -117.6321629 };
        const positionThird = { lat: 41.7206035, lng: -87.5892215 };
        const positionFourth = { lat: 37.9456121, lng: -121.2965212 };
        // eslint-disable-next-line no-undef
        const map = new google.maps.Map(document.getElementById("map"), {
          zoom: 4,
          center: { lat: 38.6281973, lng: -106.9160715 },
          styles: stylesMap,
        });
        // eslint-disable-next-line no-undef
        new google.maps.Marker({ position: positionFirst, map });
        // eslint-disable-next-line no-undef
        new google.maps.Marker({ position: positionSecond, map });
        // eslint-disable-next-line no-undef
        new google.maps.Marker({ position: positionThird, map });
        // eslint-disable-next-line no-undef
        new google.maps.Marker({ position: positionFourth, map });
      })(),
    []
  );

  return (
    <div className="contact">
      <div className="container">
        <div className="contact__wr">
          <ContactForm />
          <ContactDescription />
        </div>
      </div>

      <div id="map" className="contact__map" />

      <Social />
    </div>
  );
};
