import React from 'react';
import { useSelector } from 'react-redux';

import { ReactComponent as MailIcon } from 'assets/images/icon/email.svg';
import { ReactComponent as PhoneIcon } from 'assets/images/icon/phone.svg';
import { divisionOfNumber } from 'utils/divisionOfNumber';

export const InventoryDescription = () => {
  const { price, name, description, make, model, year, horsepower, transSpeed, trackType } = useSelector(
    ({ inventory }) => inventory
  );
  return (
    <div className="inventory__desc">
      <div className="inventory__info-wrapper">
        <h4 className="inventory__title">{name}</h4>
        <div className="inventory__price">${divisionOfNumber(price)}</div>
      </div>
      <p>{description}</p>
      <ul className="inventory__desc_list">
        <li>
          <strong>Make</strong>
          {make}
        </li>
        <li>
          <strong>Model</strong>
          {model}
        </li>
        <li>
          <strong>Year</strong>
          {year}
        </li>
        {horsepower !== 0 && (
          <li>
            <strong>Horsepower</strong>
            {horsepower}
          </li>
        )}
        {transSpeed && (
          <li>
            <strong>Trans Speed</strong>
            {transSpeed}
          </li>
        )}
        <li>
          <strong>Truck Type</strong>
          {trackType}
        </li>
      </ul>
      <div className="inventory__desc_info">
        <div className="inventory__desc_info--item">
          <strong>Call us</strong>
          <a href="tel:+13153991889">
            <PhoneIcon />
            <span>+1 315 399 1889</span>
          </a>
        </div>
        <div className="inventory__desc_info--item">
          <strong>Email us</strong>
          <a href="mailto:service@vlineco.com">
            <MailIcon /> <span>service@vlineco.com</span>
          </a>
        </div>
      </div>
    </div>
  );
};
