import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Loader } from 'components/Loader';
import { ModalGallery } from 'components/ModalGallery';

import { getInventoryRoute } from 'utils/routesBuilder';
import { divisionOfNumber } from 'utils/divisionOfNumber';
import { getImageAPI } from 'utils/getImageAPI';

import { ReactComponent as MailIcon } from 'assets/images/icon/email.svg';
import { ReactComponent as PhoneIcon } from 'assets/images/icon/phone.svg';
import { ReactComponent as ZoomIcon } from 'assets/images/icon/zoom.svg';

export const InventoryItem = React.memo(function InventoryItem({ inventory }) {
  const [mainPhoto, setMainPhoto] = React.useState(null);
  const [images, setImages] = React.useState([]);
  const [isOpenGallery, setIsOpenGallery] = React.useState(false);

  const { id, name, description, attachments, mainPhotoIdentifier, price } = inventory;

  React.useEffect(() => {
    (async () => {
      await getImageAPI(mainPhotoIdentifier).then(image => setMainPhoto(image));
    })();
  }, [mainPhotoIdentifier, setMainPhoto])

  React.useEffect(() => {
    if (isOpenGallery) {
      (async () => {
        await Promise.all(attachments.map(attachment => getImageAPI(attachment))).then(resp =>
          setImages(resp)
        );
      })()
    }
  }, [attachments, mainPhotoIdentifier, isOpenGallery, setImages]);

  const handleOpenGallery = () => setIsOpenGallery(true);
  const handleCloseGallery = () => setIsOpenGallery(false);

  return (
    <div className="inventories__item">
      <div onClick={handleOpenGallery} className="inventories__item_img">
        {mainPhoto ? <img src={mainPhoto || 'unknown'} alt="image" /> : <Loader />}
        <span>$ {divisionOfNumber(price)}</span>
        <i>
          <ZoomIcon />
        </i>
      </div>
      <div className="inventories__item_content">
        <Link to={getInventoryRoute(id, name)} className="inventories__item_name">
          {name}
        </Link>
        <p className="inventories__item_desc">{description}</p>
        <div className="inventories__item_contact">
          <div className="inventories__item_contact--item">
            <div className="icon">
              <PhoneIcon />
            </div>
            <a href="tel:+13153991889">+1 315 399 1889</a>
          </div>
          <div className="inventories__item_contact--item">
            <div className="icon">
              <MailIcon />
            </div>
            <a href="mailto:service@vlineco.com">service@vlineco.com</a>
          </div>
        </div>
      </div>
      {isOpenGallery && (
        <ModalGallery onCloseRequest={handleCloseGallery} images={[mainPhoto, ...images]} />
      )}
    </div>
  );
});

InventoryItem.propTypes = {
  inventory: PropTypes.object,
};
