import {combineReducers} from 'redux';
import {positionReducer} from '../moduls/position/positionReducer';
import {confirmModalReducer} from '../moduls/confirmModal';
import {inventoryReducer} from '../moduls/Invenroty/reducers';
import {mediaReducer} from '../moduls/Media/reducers';

export const rootReducer = combineReducers({
  position: positionReducer,
  inventory: inventoryReducer,
  media: mediaReducer,
  confirmModal: confirmModalReducer,
});
