import { errorAction, fetchAction, startAction } from 'store/actions';
import {
  ERROR_FETCH_MEDIA_ATTACHMENTS,
  ERROR_FETCH_MEDIA_MAIN_PHOTO,
  START_FETCH_MEDIA_ATTACHMENTS,
  START_FETCH_MEDIA_MAIN_PHOTO,
  SUCCESS_FETCH_MEDIA_ATTACHMENTS,
  SUCCESS_FETCH_MEDIA_MAIN_PHOTO,
} from './types';
import { getImageAPI } from 'utils/getImageAPI';

export const fetchMainPhotoImages = ({ mainPhotoIdentifier }) => async (dispatch, getState) => {
  dispatch(startAction(START_FETCH_MEDIA_MAIN_PHOTO));
  try {
    const data = await getImageAPI(mainPhotoIdentifier);
    dispatch(fetchAction({ type: SUCCESS_FETCH_MEDIA_MAIN_PHOTO, payload: data }));
  } catch (error) {
    dispatch(errorAction({ type: ERROR_FETCH_MEDIA_MAIN_PHOTO, error }));
  }
};

export const fetchAttachmentsImages = ({ attachments }) => async (dispatch, getState) => {
  dispatch(startAction(START_FETCH_MEDIA_ATTACHMENTS));
  try {
    await Promise.all(attachments.map(attachment => getImageAPI(attachment))).then(data =>
      dispatch(fetchAction({ type: SUCCESS_FETCH_MEDIA_ATTACHMENTS, payload: data }))
    );
  } catch (error) {
    dispatch(errorAction({ type: ERROR_FETCH_MEDIA_ATTACHMENTS, error }));
  }
};
