import React from 'react';
import {useFormContext} from 'react-hook-form';
import PropTypes from 'prop-types';
import {ErrorMessage} from '@hookform/error-message';

export const TextArea = ({name, label, placeholder, className = ''}) => {
  const {
    register,
    formState: {errors},
  } = useFormContext();

  const error = errors?.[name];

  return (
    <div className={`field ${className}`}>
      <label htmlFor="field" className="field__label">
        {label && <span className="field__text">{label}</span>}
        <span className="field__text field__error">
          <ErrorMessage errors={errors} name={name} />
        </span>
      </label>
      <textarea
        id="field"
        name={name}
        placeholder={placeholder}
        className={`field__input field__textarea ${error && 'error'}`}
        {...register(name, {required: 'Required'})}
      />
    </div>
  );
};

TextArea.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
};
