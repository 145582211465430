import React from 'react';
import {Header, Positions, Social} from '../../components';

import HeaderBg from '../../assets/images/header_hiring.jpg';

export const Hire = () => {
  return (
    <>
      <Header firstWord="Join" secondWord="Our Team" bgImg={HeaderBg} />
      <Positions />
      <Social />
    </>
  );
};
