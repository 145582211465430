import React from 'react';
import {Header, InventoriesList, Social} from '../../components';

import HeaderBg from '../../assets/images/inventary-for-sale.jpg';

export const InventoriesForSale = () => {
  return (
    <>
      <Header firstWord="Inventory" secondWord="For Sale" bgImg={HeaderBg} />
      <InventoriesList />
      <Social />
    </>
  );
};
