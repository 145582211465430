import React from 'react';
import PropTypes from 'prop-types';
import {Portal} from './Portal';

export const Modal = ({label, children, isOpen, onClose, className = ''}) => {
  if (!isOpen) return null;

  return (
    <Portal onClose={onClose}>
      <div className={`modal ${className}`} role="dialog">
        <button
          type="button"
          className="modal__overlay"
          role="button"
          tabIndex={0}
          onClick={onClose}
        />

        <div className="modal__content">
          <div className="modal__top">
            {label && <h3 className="modal__label">{label}</h3>}
            <button
              type="button"
              className="modal__cross"
              role="button"
              tabIndex={0}
              onClick={onClose}
            />
          </div>
          {children}
        </div>
      </div>
    </Portal>
  );
};

Modal.propTypes = {
  children: PropTypes.element.isRequired,
  label: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  className: PropTypes.string,
};
