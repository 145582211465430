import React from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { images } from './config';

import { ReactComponent as MissionsIcon } from '../../assets/images/icon/MissionsIcon.svg';
import { ReactComponent as MethodsIcon } from '../../assets/images/icon/MethodsIcon.svg';
import { ReactComponent as AssuranceIcon } from '../../assets/images/icon/AssuranceIcon.svg';

const blocks = [
  {
    icon: <MissionsIcon />,
    title: 'Missions',
    desc:
      'Our main mission is to safely deliver your freights to the right place, and on time. We can proudly promise that with our experienced drivers, and qualified dispatchers you may confidently trust your freights with our company.',
  },
  {
    icon: <MethodsIcon />,
    title: 'Methods',
    desc:
      '“Effective and Efficient” - are two main methods of obtaining results in our company. Our logistic team does not only focus on obtaining the result but also manages to achieve this in the most efficient way.',
    classN: 'black',
  },
  {
    icon: <AssuranceIcon />,
    title: 'Assurance',
    desc:
      'Our fleet of new trucks and the team of talented and experienced mechanics minimizes the chance of delaying your goods due to any malfunctions.',
  },
];

export const Benefits = () => {
  const heroTrigger = React.useRef(null);
  const heroImage = React.useRef(null);
  const heroScreen = React.useRef(null);

  const isDesktop = 767 <= window.innerWidth

  React.useEffect(() => {
    if (isDesktop) {
      gsap.registerPlugin(ScrollTrigger);
      const obj = { curImg: 0 };
      const UpdateImage = heroImage.current;

      gsap.to(heroTrigger.current, {
        scrollTrigger: {
          trigger: heroTrigger.current,
          start: 'top top',
          scrub: 1,
          end: 'bottom center',
          pin: heroScreen.current,
        },
      });

      const tlSequenceAnimation = gsap.timeline({
        scrollTrigger: {
          trigger: heroTrigger.current,
          scrub: true,
          anticipatePin: 1,
          start: 'top top',
          end: 'bottom center',
        },
      });

      tlSequenceAnimation.to(obj, {
        curImg: images.length - 1, // animate propery curImg to number of images
        roundProps: 'curImg', // only integers so it can be used as an array index
        immediateRender: true, // load first image automatically
        ease: 'none', // show every image the same ammount of time
        onUpdate: () => {
          UpdateImage.setAttribute('src', images[obj.curImg]);
        },
      });
    }
  }, [isDesktop]);

  return (
    <div className="benefits" ref={heroTrigger}>
      {blocks.map(b => (
        <div key={b.title} className={`benefits__wrapper ${b.classN || ''}`}>
          <div className="container">
            <div className="benefits__block">
              <div className="benefits__icon">{b.icon}</div>
              <h2 className="benefits__title">{b.title}</h2>
              <p className="benefits__desc">{b.desc}</p>
            </div>
          </div>
        </div>
      ))}
      {isDesktop && <div className="benefits__car" ref={heroScreen}>
        <img ref={heroImage} src={images[0]} alt="truck-image" />

        {images.map(img => (
          <img key={img} src={img} alt="none" className='benefits__car--none' />
        ))}
      </div>}
    </div>
  );
};
