import React from 'react';

import VideoPoster from '../../assets/images/video-poster.jpg';
// import Video from '../../assets/headerVideo/video.mp4';

export const HeaderVideo = () => (
  <header className="headerVideo">
    <div className="headerVideo__video">
      <video width="1920" height="600" autoPlay loop muted poster={VideoPoster}>
        {/*<source src={Video} type="video/mp4" />*/}
      </video>
    </div>

    <div className="container headerVideo__wr">
      <div className="headerVideo__desc">
        <h1>
          Pack It <br /> Secure It <br /> Ship It
        </h1>
        <p>
          Keep your circle small and your mind at peace. <br />
          No Broker, No Middle Man,
          <span>Just You And V Line Inc</span>
        </p>

        {/*<a href="#" className="btn btn--white">*/}
        {/*  <span>Online application</span>*/}
        {/*</a>*/}
      </div>
      <div className="headerVideo__application">
        <div className="headerVideo__application_wr">
          <p>Become a driver</p>
          <a href="tel:+13153991889">+1 315 399 1889</a>
        </div>
        <a
          href="https://form.jotform.com/210386087492057"
          className="btn btn--white"
          target="_blank"
          rel="noreferrer"
        >
          <span>Apply now</span>
        </a>
      </div>
    </div>
  </header>
);
