import React from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import PropTypes from 'prop-types';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {ErrorMessage} from '@hookform/error-message';

export const DataPiker = ({
  name,
  rules,
  placeholder,
  label,
  errorMessage,
  isRequired,
  ...props
}) => {
  const {
    control,
    formState: {errors},
  } = useFormContext();

  const error = errors?.[name];

  return (
    <div className="field dataPicker">
      <label htmlFor="field" className="field__label">
        {label && <span className="field__text">{label}</span>}
        <span className="field__text field__error">
          <ErrorMessage errors={errors} name={name} message={errorMessage} />
        </span>
      </label>

      <Controller
        control={control}
        name={name}
        rules={{required: 'Required'}}
        render={({field: {onChange, value}}) => (
          <ReactDatePicker
            autoComplete="off"
            placeholderText={placeholder}
            onChange={onChange}
            selected={value}
            className={`field__input ${error && 'error'}`}
            {...props}
          />
        )}
      />
    </div>
  );
};

DataPiker.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  isRequired: PropTypes.bool,
  rules: PropTypes.object,
  type: PropTypes.string,
  label: PropTypes.string,
  errorMessage: PropTypes.string,
  disabled: PropTypes.bool,
  validation: PropTypes.object,
};
