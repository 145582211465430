import React from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import InputMask from 'react-input-mask';
import PropTypes from 'prop-types';
import {ErrorMessage} from '@hookform/error-message';

export const MaskInput = ({name, label, errorMessage}) => {
  const {
    control,
    formState: {errors},
  } = useFormContext();

  const error = errors?.[name];

  return (
    <Controller
      name={name}
      control={control}
      rules={{required: 'Required'}}
      render={({field: {onChange, value}}) => (
        <InputMask mask="+1999-999-9999" maskChar="X" value={value} onChange={onChange}>
          {inputProps => (
            <div className="field">
              <label htmlFor="field" className="field__label">
                {label && <span className="field__text">{label}</span>}
                <span className="field__text field__error">
                  <ErrorMessage errors={errors} name={name} message={errorMessage} />
                </span>
              </label>
              <input
                {...inputProps}
                id="field"
                type="tel"
                className={`field__input ${error && 'error'}`}
                placeholder="+1"
              />
            </div>
          )}
        </InputMask>
      )}
    />
  );
};

MaskInput.defaultProps = {
  type: 'text',
};

MaskInput.propTypes = {
  name: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
  label: PropTypes.string,
};
