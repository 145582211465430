export const submitAdapter = value => ({
  positionId: value.positionId,
  fullName: value.fullName,
  email: value.email,
  birthday: value.birthday,
  currentAddress: value.currentAddress,
  phoneNumber: value.phoneNumber,
  lastEmployerInfo: {
    employer: value.employer,
    position: value.position,
    startDate: value.startDate,
    reasonForLeaving: value.reasonForLeaving,
  },
});
