import React from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import PropTypes from 'prop-types';

export const ModalGallery = ({images, index = 0, onCloseRequest}) => {
  const [photoIndex, setPhotoIndex] = React.useState(index);

  return (
    <Lightbox
      mainSrc={images[photoIndex]}
      nextSrc={images[(photoIndex + 1) % images.length]}
      prevSrc={images[(photoIndex + images.length - 1) % images.length]}
      onCloseRequest={onCloseRequest}
      onMovePrevRequest={() =>
        setPhotoIndex(photoIndex => (photoIndex + images.length - 1) % images.length)
      }
      onMoveNextRequest={() => setPhotoIndex(photoIndex => (photoIndex + 1) % images.length)}
    />
  );
};

ModalGallery.propTypes = {
  index: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  images: PropTypes.array.isRequired,
  onCloseRequest: PropTypes.func.isRequired,
};
