import React from 'react';

import {Benefits, HeaderVideo, JoinTeam, Social} from '../../components';
import {HomeServices} from '../../components/HomeServices';

export const Home = () => (
  <>
    <HeaderVideo />
    <Benefits />
    <JoinTeam />
    <HomeServices />
    <Social />
  </>
);
