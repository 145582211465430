import React from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { NextArrow, PrevArrow } from 'common/Arrow';
import { ModalGallery } from 'components/ModalGallery';
import { Loader } from 'components/Loader';

export const Carousel = ({ images }) => {
  const [photoIndex, setPhotoIndex] = React.useState(0);
  const [isOpenGallery, setIsOpenGallery] = React.useState(false);
  const [nav1, setNav1] = React.useState(null);
  const [nav2, setNav2] = React.useState(null);
  const slider1 = React.useRef(null);
  const slider2 = React.useRef(null);

  React.useEffect(() => {
    setNav1(slider1.current);
    setNav2(slider2.current);
  }, []);

  const handleOpenGallery = () => setIsOpenGallery(true);
  const handleCloseGallery = () => setIsOpenGallery(false);

  const settingsSliderMain = {
    lazyLoad: true,
    asNavFor: nav2,
    ref: slider1,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const settingsSliderThumb = {
    lazyLoad: true,
    asNavFor: nav1,
    ref: slider2,
    slidesToShow: 3,
    swipeToSlide: true,
    focusOnSelect: true,
    centerMode: true,
  };

  return (
    <>
      <div className="carousel">
        <Slider {...settingsSliderMain} className="carousel__main">
          {images?.map((img, i) => (
            <div
              key={i}
              className="carousel__main_image"
              onClick={() => {
                setPhotoIndex(i);
                handleOpenGallery();
              }}
            >
              {img ? <img src={img || 'unknown'} alt="image" /> : <Loader />}
            </div>
          ))}
        </Slider>
        {2 < images.length && (
          <Slider {...settingsSliderThumb}>
            {images?.map((img, i) => (
              <CustomSlide key={i} className="carousel__thumb_wr">
                <div className="carousel__thumb">
                  <img src={img} alt="image" />
                </div>
              </CustomSlide>
            ))}
          </Slider>
        )}
      </div>
      {isOpenGallery && (
        <ModalGallery images={images} index={photoIndex} onCloseRequest={handleCloseGallery} />
      )}
    </>
  );
};

const CustomSlide = ({ children, ...props }) => {
  return <div {...props}>{children}</div>;
};

CustomSlide.propTypes = {
  children: PropTypes.element,
};

Carousel.propTypes = {
  images: PropTypes.array.isRequired,
};
