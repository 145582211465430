import {
  ERROR_FETCH_MEDIA_ATTACHMENTS,
  ERROR_FETCH_MEDIA_MAIN_PHOTO,
  START_FETCH_MEDIA_ATTACHMENTS,
  START_FETCH_MEDIA_MAIN_PHOTO,
  SUCCESS_FETCH_MEDIA_ATTACHMENTS,
  SUCCESS_FETCH_MEDIA_MAIN_PHOTO,
} from './types';

const initialState = {
  mainPhoto: null,
  attachments: [],
  error: null,
  loading: false,
};

export const mediaReducer = (state = initialState, action) => {
  const {type, payload} = action;

  switch (type) {
    case SUCCESS_FETCH_MEDIA_MAIN_PHOTO: {
      return {
        ...state,
        mainPhoto: payload,
        loading: false,
      };
    }
    case SUCCESS_FETCH_MEDIA_ATTACHMENTS: {
      return {
        ...state,
        attachments: [...payload],
        loading: false,
      };
    }

    case START_FETCH_MEDIA_MAIN_PHOTO:
    case START_FETCH_MEDIA_ATTACHMENTS: {
      return {
        ...state,
        loading: true,
      };
    }

    case ERROR_FETCH_MEDIA_MAIN_PHOTO:
    case ERROR_FETCH_MEDIA_ATTACHMENTS: {
      return {
        ...state,
        error: payload,
        loading: false,
      };
    }

    default:
      return state;
  }
};
